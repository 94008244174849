.enp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: max-content;
    background-color: var(--clr-base-1);
    z-index: 1104;
    display: flex;
    justify-content: center;
    overflow: auto;
    padding: 4rem 1rem;
}

.enp-wrapper .sections {
    height: max-content;
}

.enp :where(.content) {
    max-width: 37rem;
    text-align: center;
}

.enp :where(img) {
    width: 100%;
    max-width: 382px;
    height: auto;
    margin-bottom: 1.5rem;
}

.enp :where(.d-md-bold) {
    font-size: var(--d-md);
    font-weight: var(--fw-black);
    line-height: var(--d-lh-md);
    margin-bottom: .75rem;
}

.enp :where(.t-xl-regular) {
    font-size: var(--t-xl);
    font-weight: var(--fw-regular);
    line-height: var(--t-lh-xl);
    margin-bottom: 2rem;
}

.enp :where(a) {
    border-radius: 1rem;
    padding: 1rem 1.25rem;
    background-color: var(--clr-primary-6);
    display: flex;
    justify-content: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-inline: auto;
    transition: background-color 300ms ease-out;
}

.enp :where(.t-xl-medium) {
    color: var(--clr-base-1);
    font-size: var(--t-xl);
    font-weight: var(--fw-medium);
    line-height: var(--t-lh-xl);
}

.enp :where(a:hover) {
    background-color: var(--clr-primary-7);
}

.enp :where(a:active:hover) {
    background-color: var(--clr-primary-8);
}

@media screen and (min-width: 992px) {
    .enp :where(.de-d-lg-bold) {
        font-size: var(--d-lg);
        font-weight: var(--fw-bold);
        line-height: var(--d-lh-lg);
    }
}
