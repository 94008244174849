.bg,
.bg2 {
    background-image: linear-gradient(rgba(255, 255, 255, 15.36%), rgb(255, 255, 255)), url(../../assets/img/Vector.svg);
    width: 100%;
    background-position-x: 5rem;
    background-position: top center;
    max-height: 1024px;
    height: 100%;
    position: absolute;
    z-index: -999;
}

.bg {
    top: -3.1rem;
}

.bg2 {
    bottom: 0;
    rotate: 180deg;
}