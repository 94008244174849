.container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--clr-base-1);
  z-index: 1150;
  padding: 1rem;
}

.innerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  margin-top: 5rem;
}

.content {
  text-align: center;
}

.title {
  font-size: var(--d-md);
  line-height: var(--d-lh-md);
}

.description {
  font-size: var(--t-xl);
  line-height: var(--t-lh-xl);
  font-weight: var(--fw-regular);
  margin-top: 0.5rem;
}

.errorText {
  font-size: var(--t-sm);
  line-height: var(--t-lh-sm);
  font-weight: var(--fw-regular);
  margin-top: 0.5rem;
  width: 100%;
  max-width: 24rem;
  text-align: start;
  color: var(--clr-red-6);
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  flex-direction: column;
}

.input {
  border-radius: 1rem;
  border: 1px solid var(--clr-gray-2);
  padding: 1rem 1.25rem;
  font-size: var(--t-xl);
  line-height: var(--t-lh-xl);
  font-weight: var(--fw-regular);
  width: 100%;
  color: var(--clr-gray-10);
  box-sizing: border-box;
}

.invalid {
  border-color: var(--clr-red-6);
}

.input::placeholder {
  color: var(--clr-gray-4);
}

.input:focus {
  outline: none;
}

.toggleButton {
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: var(--t-md);
  color: var(--clr-gray-4);
}

.toggleButton:focus {
  outline: none;
}

.eyeIcon {
  height: 24px;
  width: 24px;
  display: block;
  margin: auto;
}

.inputAndToggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 24rem;
}

.closeButton {
  height: 3rem;
  width: 3rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background-color: var(--clr-gray-1);
  border: 1px solid var(--clr-gray-1);
  border-radius: 1rem;
  transition: background-color 300ms ease-out;
}

.closeButton:hover {
  background-color: var(--clr-gray-2);
}

.closeButton:hover:active {
  background-color: var(--clr-gray-2);
}

@media screen and (min-width: 992px) {
  .title {
    font-size: var(--d-lg);
    line-height: var(--d-lh-lg);
  }

  .innerContainer {
     margin-top: 11.75rem;
  }
}
