.section {
  width: 100%;
}

.container {
  margin-inline: auto;
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  margin-bottom: 4rem;
}

.cover {
  height: 300px;
  background-position: center;
  background-size: cover;
  margin-inline: auto;
  width: 100%;
}

.innerContainer {
  max-width: 800px;
  margin-inline: auto;
  padding-inline: 1rem;
}


.title {
  font-size: var(--d-sm);
  line-height: var(--d-lh-sm);
  font-weight: var(--fw-black);
  margin-bottom: .5rem;
  padding-top: 2rem;
}

.description,
.description2 {
  font-size: clamp(var(--t-md), 0.899rem + 0.39vw, var(--t-xl));
  line-height: clamp(var(--t-lh-md), 1.349rem + 0.58vw, var(--t-lh-xl));
  font-weight: var(--fw-medium);
}

.description1 {
  font-size: var(--t-sm);
  line-height: var(--t-lh-sm);
  font-weight: var(--fw-regular);
}

.description2 {
  color: var(--clr-gray-10);
}

.descriptionPadding {
  padding-bottom: 2rem;
}


.columnContainer,
.columnContainer2 {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

@media screen and (min-width: 768px) {
  .innerContainer {
    padding-inline: 3rem;
  }

  .columnContainer {
    flex-direction: row;
  }

  .columnContainer2 {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 1rem;
  }

  .column {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }

  .column2 {
    flex: 1 1 calc(50% - 1rem);
  }
}

@media screen and (min-width: 992px) {
  .cover {
    height: 500px;
  }

  .container {
    align-items: center;
    column-gap: 2rem;
    /* flex-direction: row-reverse; */
  }

  .innerContainer {
    padding-inline: 0rem;
  }
}