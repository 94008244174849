.logo-content img {
    max-height: 40px;
    width: max-content;
    margin-bottom: .75rem;
}

.logo-content .t-lg-regular {
    margin-bottom: 1.5rem;
    font-size: var(--t-lg);
    font-weight: var(--fw-regular);
    line-height: var(--t-lh-lg);
}

.social-content {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 4rem;

    -webkit-display: flex; /* WebKit */
    -moz-display: flex; /* Mozilla */
    -ms-display: flex; /* Microsoft */
    -o-display: flex; /* Opera */

    align-items: center; /* Standard syntax */
    -webkit-align-items: center; /* WebKit */
    -moz-align-items: center; /* Mozilla */
    -ms-align-items: center; /* Microsoft */
    -o-align-items: center; /* Opera */
}

.social-content a {
    padding: .75rem 1rem;
    border-radius: .75rem;
    border: 1px solid var(--clr-gray-1);
    background-color: var(--clr-gray-1);

    display: flex; /* Standard syntax */
    -webkit-display: flex; /* WebKit */
    -moz-display: flex; /* Mozilla */
    -ms-display: flex; /* Microsoft */
    -o-display: flex; /* Opera */

    align-items: center; /* Standard syntax */
    -webkit-align-items: center; /* WebKit */
    -moz-align-items: center; /* Mozilla */
    -ms-align-items: center; /* Microsoft */
    -o-align-items: center; /* Opera */

    justify-content: center; /* Standard syntax */
    -webkit-justify-content: center; /* WebKit */
    -moz-justify-content: center; /* Mozilla */
    -ms-justify-content: center; /* Microsoft */
    -o-justify-content: center; /* Opera */

    transition: all 300ms ease-out; /* Standard syntax */
    -webkit-transition: all 300ms ease-out; /* WebKit */
    -moz-transition: all 300ms ease-out; /* Mozilla */
    -ms-transition: all 300ms ease-out; /* Microsoft */
    -o-transition: all 300ms ease-out; /* Opera */
}

.social-content a:hover {
    border: 1px solid var(--clr-gray-2);
}

.social-content a:active:hover {
    background-color: var(--clr-gray-2);
}

.ls .t-md-regular {
    font-size: var(--t-md);
    font-weight: var(--fw-regular);
    line-height: var(--t-lh-md);
    text-align: center;
}

@media screen and (min-width: 992px) {
    .ls {
        row-gap: 4rem;
        
        display: flex; /* Standard syntax */
        -webkit-display: flex; /* WebKit */
        -moz-display: flex; /* Mozilla */
        -ms-display: flex; /* Microsoft */
        -o-display: flex; /* Opera */

        flex-direction: column; /* Standard syntax */
        -webkit-flex-direction: column; /* WebKit */
        -moz-flex-direction: column; /* Mozilla */
        -ms-flex-direction: column; /* Microsoft */
        -o-flex-direction: column; /* Opera */
    }

    .ls .content:nth-child(1) {
        display: flex; /* Standard syntax */
        -webkit-display: flex; /* WebKit */
        -moz-display: flex; /* Mozilla */
        -ms-display: flex; /* Microsoft */
        -o-display: flex; /* Opera */

        justify-content: space-between; /* Standard syntax */
        -webkit-justify-content: space-between; /* WebKit */
        -moz-justify-content: space-between; /* Mozilla */
        -ms-justify-content: space-between; /* Microsoft */
        -o-justify-content: space-between; /* Opera */
    }
}
