.header {
    left: 0;
    padding: var(--hdr-padding);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1100;
    margin-inline: auto;
    right: 0;
}

.nav {
    border-bottom: 1px solid var(--clr-gray-2);
    align-items: center;
    display: flex;
    height: 6rem;
    width: 100%;
    background-color: var(--clr-base-1);
    justify-content: center;
}

.nav-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    column-gap: 1rem;
    -webkit-align-items: center;
    -webkit-display: flex;
    -webkit-justify-content: space-between;
    -webkit-column-gap: 1rem;
}

.nav-logo-container {

}

.nav-logo-container a {
    display: flex;
    align-items: center;
    -webkit-display: flex;
    -webkit-align-items: center;
}

.logo-link {

}

.menu-container {
    position: absolute;
    top: 6rem;
    left: 0;
    width: 100%;
    min-height: calc(100vh - 6rem);
    max-height: calc(100vh - 6rem);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    padding-block: 4rem;
    background-color: var(--clr-base-1);
    transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    visibility: hidden;
    z-index: -999;
}

.menu-container.open {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}

.menu-container.closed {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
}

.nav-list {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    -webkit-flex-direction: column;
    -webkit-gap: 1rem;
}

.nav-items {
    cursor: pointer;
}

.nav-links:hover:not(.getit, .logo-link) {
    background-color: var(--clr-gray-1);
}

.nav-links:active:hover:not(.getit, .logo-link) {
    background-color: var(--clr-gray-2);
}

.nav-links {
    padding: 1rem 1.25rem;
    color: var(--clr-gray-10);
    font-size: var(--t-xl);
    font-weight: var(--fw-medium);
    line-height: var(--t-lh-xl);
    transition: background-color 300ms ease-out;
    -webkit-transition: background-color 300ms ease-out;
    -moz-transition: background-color 300ms ease-out;
    -o-transition: background-color 300ms ease-out;
    border-radius: 1rem;
    display: block;
}

.getit .nav-links {
    background-color: var(--clr-primary-6);
    color: var(--clr-base-1);
    border-radius: 1rem;
    transition: background-color 300ms ease-out;
    -webkit-transition: background-color 300ms ease-out;
    -moz-transition: background-color 300ms ease-out;
    -o-transition: background-color 300ms ease-out;
}

.getit .nav-links:hover {
    background-color: var(--clr-primary-7);
}

.getit .nav-links:active:hover {
    background-color: var(--clr-primary-8);
}

.getit .nav-links {
    color: var(--clr-base-1);
}

.open-menu,
.close-menu {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
}

.close-menu {
    display: none;
}

.menu-btn {
    display: flex;
    height: 3rem;
    width: 3rem;
    position: relative;
    background-color: var(--clr-gray-1);
    border-radius: 1rem;
    cursor: pointer;
    padding: .75rem;
    transition: background-color 300ms ease-out;
    -webkit-transition: background-color 300ms ease-out;
    -moz-transition: background-color 300ms ease-out;
    -o-transition: background-color 300ms ease-out;
}

.menu-btn:active {
    background-color: var(--clr-gray-2);
}

.open-menu:active,
.close-menu:active,
.open-menu:focus,
.close-menu:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
}

@media screen and (min-width: 992px) {
    .header.hidden {
        top: 1.5rem; 
        padding-inline: 1rem;
    }
    
    .nav.scroll {
        max-width: calc(100% - 8rem);
        border-radius: 1rem;
    }
      
    .header.visible {
        top: 0;
    }

    .header {
        transition: top 600ms ease-in-out,
                    padding-inline 600ms ease-in-out;
    }
    
    .nav {
        border: 1px solid var(--clr-gray-2);
        height: 6rem;
        max-width: 100%;
        margin-inline: auto;
        transition: max-width 600ms ease-in-out, 
                    border-radius 600ms ease-in-out;
    }

    .nav-container {
        padding: 1.5rem;
    }

    .menu-btn {
        display: none;
    }

    .menu-container {
        display: flex;
        position: static;
        height: auto;
        flex-direction: row;
        overflow: visible;
        padding: 0;
        width: 100%;
        z-index: 1100;
        background-color: transparent;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        -o-flex-direction: row;
        min-height: auto;
        max-height: auto;
    }

    .menu-container.closed {
        transform: translateY(0);
        z-index: 1100;
        visibility: visible;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
    }

    .nav-list {
        margin-left: auto;
        flex-direction: row;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        -o-flex-direction: row;
    }

    .nav-links {
        font-size: var(--t-md);
        line-height: var(--t-lh-md);
    }
}
