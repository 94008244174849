/* Card1.module.css */
.card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.icon {
    max-width: 64px;
    height: auto;
}

.cardTitle {
    font-size: clamp(var(--t-lg), 1.075rem + 0.19vw, var(--t-xl));
    line-height: clamp(var(--t-lh-lg), 1.7rem + 0.19vw, var(--t-lh-xl));
    font-weight: var(--fw-bold);
    color: var(--clr-gray-10);
    margin-bottom: .5rem;
}

.cardDescription {
    font-size: clamp(var(--t-md), 0.95rem + 0.19vw, var(--t-lg));
    line-height: clamp(var(--t-lh-md), 1.399rem + 0.39vw, var(--t-lh-lg));
    font-weight: var(--fw-regular);
}

.cardContent {
    padding: 1.5rem;
    border-radius: 1.5rem;
    border: 1px solid var(--clr-gray-2);
    display: flex;
    column-gap: .75rem;
}

@media screen and (min-width: 768px) {
    .card {
        flex-wrap: wrap;
        flex-direction: initial;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(33.33% - 12px);
    }
}


@media screen and (min-width: 768px) {
    .cardContent {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(33.33% - 12px);
    }
}