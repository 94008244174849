.section {
  width: 100%;
}

.container {
  max-width: 800px;
  margin-inline: auto;
  padding: 4rem 1rem;
  display: flex;
  row-gap: 2rem;
  flex-direction: column;
}

.content {
  width: 100%;
}

.tcb2 {
  flex-direction: column-reverse;
}

@media screen and (min-width: 768px) {
  .container {
    padding-inline: 3rem;
  }
}

@media screen and (min-width: 992px) {
  .container {
    align-items: center;
    column-gap: 2rem;
    flex-direction: row-reverse;
    padding-inline: 0;
  }
}

.title {
  font-size: var(--d-sm);
  line-height: var(--d-lh-sm);
  font-weight: var(--fw-black);
  margin-bottom: 0.5rem;
}

.description {
  font-size: clamp(var(--t-md), 0.899rem + 0.39vw, var(--t-xl));
  line-height: clamp(var(--t-lh-md), 1.349rem + 0.58vw, var(--t-lh-xl));
  font-weight: var(--fw-regular);
}

.image {
  max-width: 100%;
  height: auto;
}