.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--clr-base-1);
    z-index: 1150;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}

.content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    max-height: 80%;
}

.content img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 1rem;
}

.closeButton {
    height: 3rem;
    width: 3rem;
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    background-color: var(--clr-base-1);
    border: 1px solid var(--clr-gray-1);
    border-radius: 1rem;
    margin-bottom: 1rem;
    transition: background-color 300ms ease-out; 
}

.closeButton img {
    height: 100%;
    width: 100%;
}

.closeButton:hover {
    background-color: var(--clr-gray-1);
}

.closeButton:active {
    background-color: var(--clr-gray-2);
}
