.load-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--clr-base-1);
    
    z-index: 1000;

    opacity: 0;
    transition: opacity 1600ms ease-out;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.load-bg.showLoading {
    opacity: 1;
}

.load-bg.hideLoading {
    opacity: 0;
}

.loading-lottie {
    width: 200px;
    height: 200px;
}

.t-lg-bold {
    font-size: var(--t-lg);
    font-weight: var(--fw-bold);
    line-height: var(--t-lh-lg);
}
