.section {
    width: 100%;
}

.cover {
    background-position: center;
    background-size: cover;
    height: auto;
    min-height: 300px;
    margin-top: 4rem;
    margin-bottom: 1.5rem;
}

.cover,
.container,
.container3 {
    max-width: 800px;
    margin-inline: auto;
}

.container2 {
    padding-inline: 1rem;
    max-width: 100%;
}

.container3 {
    padding-inline: 1rem;
}

.container {
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.textCenter {
    text-align: center;
}

.title {
    font-size: var(--d-sm);
    line-height: var(--d-lh-sm);
    font-weight: var(--fw-black);
    margin-bottom: .5rem;
}

.description {
    font-size: clamp(var(--t-md), 0.899rem + 0.39vw, var(--t-xl));
    line-height: clamp(var(--t-lh-md), 1.349rem + 0.58vw, var(--t-lh-xl));
    font-weight: var(--fw-regular);
}

.images {
    max-width: 100%;
    height: auto;
    border-radius: 1rem;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

@media screen and (min-width: 768px) {
    .container {
        row-gap: 2rem;
    }

    .container,
    .container3 {
        padding-inline: 3rem;
    }

    .cover {
        min-height: 500px;
        margin-bottom: 2rem;
        border: none;
    }

    .cardContainer {
        flex-wrap: wrap;
        flex-direction: initial;
    }
  }
  
  @media screen and (min-width: 992px) {
    .container,
    .container3 {
      padding-inline: 0;
    }
  }