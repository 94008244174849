* {
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    cursor: url(../img/Cursor.svg), auto;
    margin: 0;
    padding: 0;
    text-size-adjust: 100%;
}

html {
    /* scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth;
    -moz-scroll-behavior: smooth;
    -ms-scroll-behavior: smooth;
    -o-scroll-behavior: smooth; */
}

body {
    font-family: var(--ff);
    scrollbar-width: none;
    scrollbar-color: var(--clr-gray-4) var(--clr-gray-1);
}

/* Customize the scrollbar for WebKit-based browsers */
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    padding: 8px;
    background: var(--clr-gray-1);
}

::-webkit-scrollbar-thumb {
    background-color: var(--clr-gray-3);
    border-radius: .5rem;
    border: 3px solid var(--clr-gray-1);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--clr-gray-4);
}

body,
.wrapper,
main {
    position: relative;
}

main {
    padding-top: 6rem;
}

.sections {
    width: 100%;
}

.containers {
    max-width: 1440px;
    margin-inline: auto;
    padding: 4rem 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--clr-gray-10);
}

p {
    color: var(--clr-gray-8);
}

a {
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
}

li {
    list-style: none;
}

img {
    object-fit: cover;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
}

html.lenis,
html.lenis body {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-smooth iframe {
    pointer-events: none;
}

@media screen and (min-width: 768px) {
    .containers {
        padding-inline: 3rem;
    }
}

@media screen and (min-width: 992px) {
    .containers {
        padding-inline: 7rem;
    }
}
