:root {
    --hdr-pad: 16px;
    --bder-rad: 16px;
    
    /* font-family */
    --ff:'Satoshi', sans-serif;
    
    /* Font weights */
    --fw-regular: 400;
    --fw-medium: 500;
    --fw-bold: 700;
    --fw-black: 900;

    /* Heading sizes */
    --d-2xl: 72px;
    --d-xl: 60px;
    --d-lg: 48px;
    --d-md: 36px;
    --d-sm: 30px;
    --d-xs: 24px;

    /* Text sizes */
    --t-xl: 20px;
    --t-lg: 18px;
    --t-md: 16px;
    --t-sm: 14px;
    --t-xs: 12px;

    /* Line-height */
    --d-lh-2xl: 90px;
    --d-lh-xl: 72px;
    --d-lh-lg: 60px;
    --d-lh-md: 44px;
    --d-lh-sm: 38px;
    --d-lh-xs: 32px;
    --t-lh-2xl: 30px;
    --t-lh-xl: 28px;
    --t-lh-lg: 24px;
    --t-lh-md: 20px;
    --t-lh-sm: 28px;
    
    /* Colors */
    --clr-primary-1: #E1E9FB;
    --clr-primary-2: #C7D3F7;
    --clr-primary-3: #ADC0F2;
    --clr-primary-4: #93ACEE;
    --clr-primary-5: #7A98E9;
    --clr-primary-6: #5f83e2;
    --clr-primary-7: #4C6BBA;
    --clr-primary-8: #395493;
    --clr-primary-9: #273C6B;
    --clr-primary-10: #152544;

    --clr-gray-1: #f5f5f5;
    --clr-gray-2: #E0E0E0;
    --clr-gray-3: #CCCCCC;
    --clr-gray-4: #B3B3B3;
    --clr-gray-5: #999999;
    --clr-gray-6: #858585;
    --clr-gray-7: #707070;
    --clr-gray-8: #5C5C5C;
    --clr-gray-9: #474747;
    --clr-gray-10: #333333;
    
    --clr-darkgray-1: #ECEDF0;
    --clr-darkgray-2: #D2D4DA;
    --clr-darkgray-3: #AEB2BD;
    --clr-darkgray-4: #888E9F;
    --clr-darkgray-5: #646C82;
    --clr-darkgray-6: #424C66;
    --clr-darkgray-7: #384157;
    --clr-darkgray-8: #2F3648;
    --clr-darkgray-9: #262B3A;
    --clr-darkgray-10: #1e222e;

    --clr-yellow-1: #FFF4D0;
    --clr-yellow-2: #FFEAA7;
    --clr-yellow-3: #FFE080;
    --clr-yellow-4: #FFD65A;
    --clr-yellow-5: #FFCC33;
    --clr-yellow-6: #FCC221;
    --clr-yellow-7: #D0A81C;
    --clr-yellow-8: #A38716;
    --clr-yellow-9: #766511;
    --clr-yellow-10: #4A430B;

    --clr-green-1: #e6f2eb;
    --clr-green-2: #c2dfd0;
    --clr-green-3: #92c5ab;
    --clr-green-4: #60ab84;
    --clr-green-5: #30915e;
    --clr-green-6: #02793b;
    --clr-green-7: #026732;
    --clr-green-8: #01562a;
    --clr-green-9: #014522;
    --clr-green-10: #01361b;

    --clr-red-1: #fbedea;
    --clr-red-2: #f6d4cc;
    --clr-red-3: #f0b1a3;
    --clr-red-4: #e88d78;
    --clr-red-5: #e16b50;
    --clr-red-6: #db4a29;
    --clr-red-7: #ba3f23;
    --clr-red-8: #9b351d;
    --clr-red-9: #7d2a17;
    --clr-red-10: #632112;

    --clr-orange-1: #fbf5e6;
    --clr-orange-2: #f6e7c2;
    --clr-orange-3: #f0d591;
    --clr-orange-4: #e8c15e;
    --clr-orange-5: #e1af2e;
    --clr-orange-6: #db9d00;
    --clr-orange-7: #ba8500;
    --clr-orange-8: #9b6f00;
    --clr-orange-9: #7d5900;
    --clr-orange-10: #634700;

    --clr-base-1: #ffffff;
    --clr-base-2: #1F1F1F;
}