.chipBlueContainerStyle,
.chipGreenContainerStyle,
.chipRedContainerStyle {
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  width: max-content;
  margin-bottom: 1rem;
}

.chipBlueContainerStyle {
  background-color: var(--clr-primary-1);
}



.chipGreenContainerStyle {
  background-color: var(--clr-green-1);
}



.chipRedContainerStyle {
  background-color: var(--clr-red-1);
}

.chipBlueTextStyle,
.chipGreenTextStyle,
.chipRedTextStyle {
  font-size: var(--t-sm);
  font-weight: var(--fw-medium);
  line-height: var(--t-lh-sm);
}

.chipBlueTextStyle {
  color: var(--clr-primary-10);
}

.chipGreenTextStyle {
  color: var(--clr-green-10);
}

.chipRedTextStyle {
  color: var(--clr-red-10);
}
