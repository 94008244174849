.btn {
    background-color: var(--clr-primary-6);
    opacity: 0.5;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    padding: 1rem 1.25rem;
    border-radius: 1rem;
    height: 62px;
    width: 62px;
    transition: opacity 300ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 997;
    border: none;
}

.showBtn:hover {
    background-color: var(--clr-primary-7);
}

.hideBtn {
    opacity: 0;
}

.showBtn {
    opacity: 0.5;
}
